import React, { Suspense } from 'react';
import { GET_BLOG_HERO } from '../queries/Hero';

import {
  HeroLoading,
  PageContentLoading,
  TagNavigationLoading,
  ArticlesNotFeaturedLoading,
  ArticlesByTagItemLoading
} from '../components/UI/Skeleton/Skeletons';

const Hero = React.lazy(() => import('../components/UI/Hero/Hero'));
const BlogContent = React.lazy(() => import('../components/Blog/BlogContent'));
const TagNavigation = React.lazy(() =>
  import('../components/UI/Layout/TagNavigation')
);
const ArticlesByTagList = React.lazy(() =>
  import('../components/Articles/ArticlesByTagList')
);
const ArticlesNotFeatured = React.lazy(() =>
  import('../components/Articles/ArticlesNotFeatured')
);
const ServiceListForArticles = React.lazy(() =>
  import('../components/Services/ServiceListForArticles')
);

const Blog = () => {
  return (
    <>
      <Suspense fallback={<HeroLoading />}>
        <Hero
          heroContent={'blog'}
          heroQuery={GET_BLOG_HERO}
          heroClassName={'heroSectionBlog'}
        />
      </Suspense>

      <Suspense fallback={<PageContentLoading />}>
        <BlogContent parent={'blog'} />
      </Suspense>

      <Suspense fallback={<TagNavigationLoading />}>
        <TagNavigation parent={'blog'} />
      </Suspense>

      <Suspense fallback={<ArticlesByTagItemLoading />}>
        <ArticlesByTagList
          className={'Three'}
          heading={'Latest news from Nockeye'}
        />
      </Suspense>

      <Suspense fallback={<ArticlesNotFeaturedLoading />}>
        <ArticlesNotFeatured parent={'blog'} />
      </Suspense>

      <Suspense fallback={<ArticlesNotFeaturedLoading />}>
        <ServiceListForArticles parent={'blog'} />
      </Suspense>
    </>
  );
};

export default Blog;
