import React from 'react';

import classes from './Skeleton.module.css';

const skeleton = ({ items, type, className, classNameContainer }) => {
  let skeletonItem = [];

  for (let i = 0; i < items; i++) {
    skeletonItem.push(<span className={classes.skeletonItem} key={i}></span>);
  }

  const skeletonType = type;
  const skeletonClassName = className;

  switch (skeletonType) {
    case 'single':
      return (
        <div className={`${classes.skeleton} ${classes[skeletonClassName]}`}>
          {skeletonItem}
        </div>
      );
    case 'pageContent':
      return (
        <div className={classes.skeletonPageContentContainer}>
          <div className={`${classes.skeleton} ${classes[skeletonClassName]}`}>
            {skeletonItem}
          </div>
        </div>
      );
    case 'articleContent':
      return (
        <div className={classes.skeletonArticleContentContainer}>
          <div className={classes.skeletonArticleLayout}>
            <div
              className={`${classes.skeleton} ${classes[skeletonClassName]}`}
            >
              {skeletonItem}
            </div>
            <div
              className={`${classes.skeleton} ${classes[skeletonClassName]} ${classes.skeletonArticleContentTag}`}
            >
              {skeletonItem}
            </div>
          </div>
        </div>
      );
    case 'cards':
      return (
        <div className={`${classes[classNameContainer]}`}>
          <div className={`${classes.skeleton} ${classes[skeletonClassName]}`}>
            {skeletonItem}
          </div>
        </div>
      );
    case 'heroImage':
      return (
        <div className={`${classes.skeleton} ${classes[skeletonClassName]}`}>
          {skeletonItem}
        </div>
      );
    default:
      // code block
      return null;
  }
};

export default skeleton;
