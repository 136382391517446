import React from 'react';
import classes from './Backdrop.module.css';

const Backdrop = ({ offCanvasBackdrop, tabIndex }) => {
  return (
    <div
      className={classes.backdrop}
      onClick={offCanvasBackdrop}
      tabIndex={tabIndex}
    ></div>
  );
};

export default Backdrop;
