import React, { Suspense } from 'react';
import { GET_BLOG_HERO } from '../queries/Hero';

import {
  HeroLoading,
  TagNavigationLoading,
  TagListLoading,
  ArticlesNotFeaturedLoading
} from '../components/UI/Skeleton/Skeletons';
const Hero = React.lazy(() => import('../components/UI/Hero/Hero'));
const TagNavigation = React.lazy(() =>
  import('../components/UI/Layout/TagNavigation')
);
const BlogNotFeaturedTag = React.lazy(() =>
  import('../components/Blog/BlogNotFeaturedTags')
);
const ServiceListForArticles = React.lazy(() =>
  import('../components/Services/ServiceListForArticles')
);

const Tag = () => {
  return (
    <>
      <Suspense fallback={<HeroLoading />}>
        <Hero
          heroContent={'blog'}
          heroQuery={GET_BLOG_HERO}
          heroClassName={'heroSectionTag'}
        />
      </Suspense>

      <Suspense fallback={<TagNavigationLoading />}>
        <TagNavigation parent={'tag'} />
      </Suspense>

      <Suspense fallback={<TagListLoading />}>
        <BlogNotFeaturedTag />
      </Suspense>

      <Suspense fallback={<ArticlesNotFeaturedLoading />}>
        <ServiceListForArticles parent={'tag'} />
      </Suspense>
    </>
  );
};

export default Tag;
