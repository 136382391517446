import React, { Suspense } from 'react';
import { GET_SERVICE_HERO } from '../queries/Hero';

import {
  HeroLoading,
  PageContentLoading,
  NewsListLoading
} from '../components/UI/Skeleton/Skeletons';
const Hero = React.lazy(() => import('../components/UI/Hero/Hero'));
const ServiceContent = React.lazy(() =>
  import('../components/Services/ServiceContent')
);
const ArticlesPageFooter = React.lazy(() =>
  import('../components/Articles/ArticlesPageFooter')
);

const Services = () => {
  return (
    <>
      <Suspense fallback={<HeroLoading />}>
        <Hero
          heroContent={'service'}
          heroQuery={GET_SERVICE_HERO}
          heroClassName={'heroSectionService'}
        />
      </Suspense>

      <Suspense fallback={<PageContentLoading />}>
        <ServiceContent />
      </Suspense>

      <Suspense fallback={<NewsListLoading />}>
        <ArticlesPageFooter />
      </Suspense>
    </>
  );
};

export default Services;
