import { gql } from '@apollo/client';

export const HERO_FRAGMENT = gql`
  fragment HeroDetail on ComponentHeroHero {
    id
    backgroundColorOne
    backgroundColorTwo
    backgroundColorThree
    alphaColorOne
    alphaColorTwo
    alphaColorThree
    positionOne
    positionTwo
    positionThree
    angle
    attributionName
    attributionNameUrl
    attributionParentName
    attributionParentUrl
    imageWebp
    imageAltText
    imageDropShadow
    coverColorOne
    coverColorTwo
    coverColorThree
  }
`;

export const SIDE_IMAGE_FRAGMENT = gql`
  fragment SideImage on ComponentSideImageSideImage {
    imageWebp
    attributionName
    attributionNameUrl
    attributionParentName
    attributionParentUrl
  }
`;

export const WEBSITE_IMAGES_FRAGMENT = gql`
  fragment WebsiteImages on ComponentArticleImagesWebsite {
    id
    imageMobileWebp
    imageDesktopWebp
    imageAltText
  }
`;

export const SINGLE_IMAGE_FRAGMENT = gql`
  fragment SingleImage on ComponentSingleImageSingleImage {
    id
    imageWebp
    imageAltText
  }
`;

export const HIGHLIGHTS_FRAGMENT = gql`
  fragment Highlights on ComponentHighlightsHighlights {
    highlightHeading
    highlight {
      id
      heading
      body
    }
  }
`;

export const MUSIC_IMAGE_FRAGMENT = gql`
  fragment MusicImage on ComponentMusicImageMusic {
    id
    imageWebp
    imageAltText
    songUrl
  }
`;

export const YOUTUBE_IMAGE_FRAGMENT = gql`
  fragment Youtube on ComponentYoutubeYoutube {
    id
    imageWebp
    imageAltText
    youtubeUrl
  }
`;

export const LINK_URL_FRAGMENT = gql`
  fragment LinkUrl on ComponentLinkUrlLinkUrl {
    id
    text
    title
    url
  }
`;

export const META_FRAGMENT = gql`
  fragment Meta on ComponentMetaMeta {
    title
    description
    image
    url
  }
`;
