import React from 'react';
import { Link } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';

import { GET_FOOTER_LINKS } from '../../../queries/Footer';
import classes from './Footer.module.css';

const Footer = () => {
  const { data } = useSuspenseQuery(GET_FOOTER_LINKS);

  return (
    <footer className={classes.footer}>
      <section className={classes['footerSection']}>
        <ul className={classes['footerSectionLinks']}>
          <li>
            <Link
              key='about'
              to={`/${data.about.data.attributes.slug}`}
              title='read more about nockeye'
            >
              About
            </Link>
          </li>
          <li>
            <Link
              key='contact'
              to={`/${data.contact.data.attributes.slug}`}
              title='contact nockeye and get block and ignored'
            >
              Contact
            </Link>
          </li>
        </ul>
        <p>
          <small>&copy; Nockeye</small>
        </p>
      </section>
    </footer>
  );
};

export default Footer;
