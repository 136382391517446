import React, { Suspense } from 'react';

import { ArticleContentLoading } from '../components/UI/Skeleton/Skeletons';
const ArticleContent = React.lazy(() =>
  import('../components/Articles/ArticleContent')
);

const Article = () => {
  return (
    <Suspense fallback={<ArticleContentLoading />}>
      <ArticleContent />
    </Suspense>
  );
};

export default Article;
