import React, { Suspense } from 'react';

import {
  ClientDetailContentLoading,
  ClientListLoading,
  NewsListLoading
} from '../components/UI/Skeleton/Skeletons';
const ClientDetail = React.lazy(() =>
  import('../components/Clients/ClientDetail')
);
const ClientList = React.lazy(() => import('../components/Clients/ClientList'));
const ArticlesPageFooter = React.lazy(() =>
  import('../components/Articles/ArticlesPageFooter')
);

const Client = () => {
  return (
    <>
      <Suspense fallback={<ClientDetailContentLoading />}>
        <ClientDetail />
      </Suspense>

      <Suspense fallback={<ClientListLoading />}>
        <ClientList parent={'client'} />
      </Suspense>

      <Suspense fallback={<NewsListLoading />}>
        <ArticlesPageFooter />
      </Suspense>
    </>
  );
};

export default Client;
