import React, { Suspense } from 'react';
import { GET_ABOUT_HERO } from '../queries/Hero';

import {
  HeroLoading,
  PageContentLoading,
  ClientListLoading,
  CoreValuesLoading,
  NewsListLoading
} from '../components/UI/Skeleton/Skeletons';
const Hero = React.lazy(() => import('../components/UI/Hero/Hero'));
const AboutContent = React.lazy(() =>
  import('../components/About/AboutContent')
);
const CoreValues = React.lazy(() =>
  import('../components/CoreValues/CoreValues')
);
const ClientList = React.lazy(() => import('../components/Clients/ClientList'));
const ArticlesPageFooter = React.lazy(() =>
  import('../components/Articles/ArticlesPageFooter')
);

const About = () => {
  return (
    <>
      <Suspense fallback={<HeroLoading />}>
        <Hero
          heroContent={'about'}
          heroQuery={GET_ABOUT_HERO}
          heroClassName={'heroSectionAbout'}
        />
      </Suspense>

      <Suspense fallback={<PageContentLoading />}>
        <AboutContent />
      </Suspense>

      <Suspense fallback={<CoreValuesLoading />}>
        <CoreValues />
      </Suspense>

      <Suspense fallback={<ClientListLoading />}>
        <ClientList parent={'about'} />
      </Suspense>

      <Suspense fallback={<NewsListLoading />}>
        <ArticlesPageFooter />
      </Suspense>
    </>
  );
};

export default About;
