import React from 'react';

import classes from './Music.module.css';

const MusicImage = () => {
  return (
    <div className={classes.musicImage}>
      <div className={classes.musicImageContent}></div>
      <svg x='0px' y='0px' viewBox='0 0 200 80'>
        <path
          fill='#fefefe'
          d='M0,0v65h3.5C30.67,65,41.44,53.88,70,53.88c13.33,0,20.33,0,20.33,0h20.54c0,0,7,0,20.33,0
		c28.56,0,38.12,11.12,65.29,11.12h3.5V0H0z'
        />
      </svg>
    </div>
  );
};

export default MusicImage;
