import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import classes from '../../../App.module.css';

import { GET_NAV_SLUG } from '../../../queries/Navigation';
import Header from './Header';
import Home from '../../../pages/Homepage';
import About from '../../../pages/About';
import Client from '../../../pages/Client';
import Service from '../../../pages/Service';
import Services from '../../../pages/Services';
import Blog from '../../../pages/Blog';
import Article from '../../../pages/Article';
import Tag from '../../../pages/Tag';
import Contact from '../../../pages/Contact';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';

const RouterMain = () => {
  const { loading, error, data } = useQuery(GET_NAV_SLUG);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  return (
    <div className={classes.app}>
      <Header />
      <main className={classes.main}>
        <Routes>
          <Route exact index element={<Home />} />
          <Route
            path={`/${data.about.data.attributes.slug}`}
            element={<About />}
          />
          <Route path='/client/:slug' element={<Client />} />
          <Route path='/service/:slug' element={<Service />} />
          <Route
            path={`/${data.service.data.attributes.slug}`}
            element={<Services />}
          />
          <Route
            path={`/${data.blog.data.attributes.slug}`}
            element={<Blog />}
          />
          <Route path='/blog/:slug' element={<Article />} />
          <Route path='/blog/:tagslug/:pageNumber' element={<Tag />} />
          <Route
            path={`/${data.contact.data.attributes.slug}`}
            element={<Contact />}
          />
        </Routes>
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default RouterMain;
