import { gql } from '@apollo/client';

export const GET_FOOTER_LINKS = gql`
  query GetFooterLinks {
    about {
      data {
        attributes {
          slug
        }
      }
    }
    contact {
      data {
        attributes {
          slug
        }
      }
    }
  }
`;
