import React from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { Link, NavLink } from 'react-router-dom';
import { GET_NAV_SLUG } from '../../../queries/Navigation';

import MenuIcon from '../../../assets/Icons/MenuIcon.svg';
import CloseIcon from '../../../assets/Icons/CloseIcon.svg';
import ArrowLeftIcon from '../../../assets/Icons/ArrowLeftIcon.svg';
import classes from './Navigation.module.css';

const Navigation = ({ offCanvasNav, tabIndex }) => {
  const { data } = useSuspenseQuery(GET_NAV_SLUG);

  return (
    <>
      <nav className={`${classes.navContent}`}>
        <button
          className={classes.offCanvasBtn}
          onClick={offCanvasNav}
          title='navigation links'
          tabIndex='0'
        >
          <img
            className={classes.closeIcon}
            width='16'
            height='16'
            src={CloseIcon}
            alt='close icon'
          />
          <img
            className={classes.menuIcon}
            width='16'
            height='16'
            src={MenuIcon}
            alt='menu icon'
          />
        </button>
        <ul>
          <li>
            <Link
              key='home'
              to={`/`}
              title='go to the homepage'
              onClick={offCanvasNav}
              tabIndex={tabIndex}
            >
              <img
                className={classes.arrowLeftIcon}
                width='16'
                height='16'
                src={ArrowLeftIcon}
                alt='arrow icon'
              />
              <span>Home</span>
            </Link>
          </li>
          <li>
            <NavLink
              key='about'
              to={`/${data.about.data.attributes.slug}`}
              title='read more about nockeye'
              onClick={offCanvasNav}
              tabIndex={tabIndex}
            >
              <img
                className={classes.arrowLeftIcon}
                width='16'
                height='16'
                src={ArrowLeftIcon}
                alt='arrow icon'
              />
              <span>About</span>
            </NavLink>
          </li>
          <li>
            <Link
              key='services'
              to={`/${data.service.data.attributes.slug}`}
              title='read about the services nockeye offers'
              onClick={offCanvasNav}
              tabIndex={tabIndex}
            >
              <img
                className={classes.arrowLeftIcon}
                width='16'
                height='16'
                src={ArrowLeftIcon}
                alt='arrow icon'
              />
              <span>Services</span>
            </Link>
          </li>
          <li>
            <Link
              key='blog'
              to={`/${data.blog.data.attributes.slug}`}
              title='read the blog posts from nockeye'
              onClick={offCanvasNav}
              tabIndex={tabIndex}
            >
              <img
                className={classes.arrowLeftIcon}
                width='16'
                height='16'
                src={ArrowLeftIcon}
                alt='arrow icon'
              />
              <span>Blog</span>
            </Link>
          </li>
          <li>
            <Link
              key='contact'
              to={`/${data.contact.data.attributes.slug}`}
              title='contact nockeye'
              onClick={offCanvasNav}
              tabIndex={tabIndex}
            >
              <img
                className={classes.arrowLeftIcon}
                width='16'
                height='16'
                src={ArrowLeftIcon}
                alt='arrow icon'
              />
              <span>Contact</span>
            </Link>
          </li>
          <li></li>
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
