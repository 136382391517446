import React, { Suspense } from 'react';
import { GET_HOME_HERO } from '../queries/Hero';

import {
  HeroLoading,
  PageContentLoading,
  ServiceListLoading,
  NewsListLoading
} from '../components/UI/Skeleton/Skeletons';
const Hero = React.lazy(() => import('../components/UI/Hero/Hero'));
const HomeContent = React.lazy(() => import('../components/Home/HomeContent'));
const ServiceList = React.lazy(() =>
  import('../components/Services/ServiceList')
);
const ArticlesPageFooter = React.lazy(() =>
  import('../components/Articles/ArticlesPageFooter')
);

const Homepage = () => {
  return (
    <>
      <Suspense fallback={<HeroLoading />}>
        <Hero
          heroContent={'home'}
          heroQuery={GET_HOME_HERO}
          heroClassName={'heroSectionHome'}
        />
      </Suspense>

      <Suspense fallback={<PageContentLoading />}>
        <HomeContent />
      </Suspense>

      <Suspense fallback={<ServiceListLoading />}>
        <ServiceList parent={'home'} />
      </Suspense>

      <Suspense fallback={<NewsListLoading />}>
        <ArticlesPageFooter />
      </Suspense>
    </>
  );
};

export default Homepage;
