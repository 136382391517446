import React, { Suspense } from 'react';

import {
  NewsListLoading,
  ArticleContentLoading,
  ServiceListLoading
} from '../components/UI/Skeleton/Skeletons';
const ServiceDetail = React.lazy(() =>
  import('../components/Services/ServiceDetail')
);
const ServiceList = React.lazy(() =>
  import('../components/Services/ServiceList')
);
const ArticlesPageFooter = React.lazy(() =>
  import('../components/Articles/ArticlesPageFooter')
);

const Service = () => {
  return (
    <>
      <Suspense fallback={<ArticleContentLoading />}>
        <ServiceDetail />
      </Suspense>

      <Suspense fallback={<ServiceListLoading />}>
        <ServiceList parent={'service'} />
      </Suspense>

      <Suspense fallback={<NewsListLoading />}>
        <ArticlesPageFooter />
      </Suspense>
    </>
  );
};

export default Service;
