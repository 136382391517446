import { gql } from '@apollo/client';
import { HERO_FRAGMENT } from './Fragments';

export const GET_HOME_HERO = gql`
  ${HERO_FRAGMENT}
  query GetHomeHero {
    home {
      data {
        attributes {
          hero {
            ...HeroDetail
          }
        }
      }
    }
  }
`;

export const GET_ABOUT_HERO = gql`
  ${HERO_FRAGMENT}
  query GetAboutHero {
    about {
      data {
        attributes {
          hero {
            ...HeroDetail
          }
        }
      }
    }
  }
`;

export const GET_BLOG_HERO = gql`
  ${HERO_FRAGMENT}
  query GetBlogHero {
    blog {
      data {
        attributes {
          hero {
            ...HeroDetail
          }
        }
      }
    }
  }
`;

export const GET_SERVICE_HERO = gql`
  ${HERO_FRAGMENT}
  query GetServiceHero {
    service {
      data {
        attributes {
          hero {
            ...HeroDetail
          }
        }
      }
    }
  }
`;

export const GET_CONTACT_HERO = gql`
  ${HERO_FRAGMENT}
  query GetContactHero {
    contact {
      data {
        attributes {
          hero {
            ...HeroDetail
          }
        }
      }
    }
  }
`;
