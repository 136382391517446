import React from 'react';

import Skeleton from './Skeleton';

export function NavigationLoading() {
  return (
    <Skeleton type={'single'} items={1} className={'skeletonNavigation'} />
  );
}

export function HeroLoading() {
  return <Skeleton type={'heroImage'} items={1} className={'skeletonHero'} />;
}

export function PageContentLoading() {
  return (
    <Skeleton
      type={'pageContent'}
      items={6}
      className={'skeletonPageContent'}
    />
  );
}

export function ServiceListLoading() {
  return (
    <Skeleton
      type={'cards'}
      items={10}
      classNameContainer={'skeletonServiceListContainer'}
      className={'skeletonServiceListCards'}
    />
  );
}

export function NewsListLoading() {
  return (
    <Skeleton
      type={'cards'}
      items={9}
      classNameContainer={'skeletonNewsListContainer'}
      className={'skeletonNewsListCards'}
    />
  );
}

export function ClientListLoading() {
  return (
    <Skeleton
      type={'cards'}
      items={10}
      classNameContainer={'skeletonClientListContainer'}
      className={'skeletonClientListCards'}
    />
  );
}

export function CoreValuesLoading() {
  return (
    <Skeleton
      type={'cards'}
      items={5}
      classNameContainer={'skeletonCoreValueContainer'}
      className={'skeletonCoreValueCards'}
    />
  );
}

export function FooterLoading() {
  return (
    <Skeleton
      type={'cards'}
      items={2}
      classNameContainer={'skeletonFooterContainer'}
      className={'skeletonFooterCards'}
    />
  );
}

export function TagNavigationLoading() {
  return (
    <Skeleton
      type={'pageContent'}
      items={1}
      className={'skeletonTagNavigation'}
    />
  );
}

export function ArticlesByTagItemLoading() {
  return (
    <Skeleton
      type={'cards'}
      items={6}
      classNameContainer={'skeletonBlogCardsContainer'}
      className={'skeletonBlogCards'}
    />
  );
}

export function ArticlesNotFeaturedLoading() {
  return (
    <Skeleton
      type={'cards'}
      items={10}
      classNameContainer={'skeletonNotFeaturedContainer'}
      className={'skeletonNotFeaturedCards'}
    />
  );
}

export function TagListLoading() {
  return (
    <Skeleton
      type={'cards'}
      items={10}
      classNameContainer={'skeletonTagListContainer'}
      className={'skeletonTagListCards'}
    />
  );
}

export function ArticleContentLoading() {
  return (
    <>
      <Skeleton type={'heroImage'} items={1} className={'skeletonHero'} />
      <TagNavigationLoading />
      <Skeleton
        type={'articleContent'}
        items={9}
        className={'skeletonArticleContent'}
      />
    </>
  );
}

export function ClientDetailContentLoading() {
  return (
    <>
      <Skeleton type={'heroImage'} items={1} className={'skeletonHero'} />
      <Skeleton
        type={'articleContent'}
        items={9}
        className={'skeletonArticleContent'}
      />
    </>
  );
}
