import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import RouterMain from './components/UI/Layout/RouterMain.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  //const GRAPHQL_URL =
  //process.env.BACKEND_URL || 'http://localhost:1337/graphql';

  const GRAPHQL_URL =
    process.env.REACT_APP_BACKEND_GRAPHQL_URL ||
    'http://localhost:1337/graphql';
  //const GRAPHQL_URL = process.env.REACT_APP_BACKEND_GRAPHQL_URL;

  //const environment = process.env.NODE_ENV;

  const client = new ApolloClient({
    uri: GRAPHQL_URL,
    cache: new InMemoryCache({
      typePolicies: {
        Article: {
          merge: true
        },
        Tag: {
          merge: true
        },
        ClientList: {
          merge: true
        },
        ServiceList: {
          merge: true
        }
      }
    })
  });

  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route path='/*' element={<RouterMain />} />
        </Routes>
      </Router>
    </ApolloProvider>
  );
};

export default App;
