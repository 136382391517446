import { gql } from '@apollo/client';

export const GET_MUSIC = gql`
  query GetMusic {
    musics {
      data {
        id
        attributes {
          title
          artist
          imageWebp
          imageWebpSmall
          imageAltText
          song
        }
      }
    }
  }
`;
