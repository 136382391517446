import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MUSIC } from '../../queries/Music';
import MusicImage from './MusicImage';

import ArrowBlack from '../../assets/Icons/ArrowRightBlackIcon.svg';
import ArrowWhite from '../../assets/Icons/ArrowRightWhiteIcon.svg';
import MusicIcon from '../../assets/Icons/MusicIcon.svg';
import PlayWhiteIcon from '../../assets/Icons/PlayWhiteIcon.svg';
import PlayBlackIcon from '../../assets/Icons/PlayBlackIcon.svg';
import PauseWhiteIcon from '../../assets/Icons/PauseWhiteIcon.svg';
import PauseBlackIcon from '../../assets/Icons/PauseBlackIcon.svg';
import classes from './Music.module.css';

const MusicPlayer = () => {
  const { loading, error, data } = useQuery(GET_MUSIC);
  const [playlist, setPlaylist] = useState([]);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioRef, setAudioRef] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [manualPause, setManualPause] = useState(false);
  const [showMusic, setShowMusic] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const initialScrollRef = useRef(0);

  function arrowNavigation(type) {
    const imageTypes = [ArrowBlack, ArrowWhite];
    const imageClassName = ['audioArrowBlack', 'audioArrowWhite'];

    return (
      <div className={`${classes.audioArrow} ${classes[`audioArrow${type}`]}`}>
        {imageTypes.map((imageType, index) => (
          <img
            key={imageType}
            src={imageType}
            width='16'
            height='16'
            alt='arrow icon'
            loading='lazy'
            className={classes[imageClassName[index]]}
          />
        ))}
      </div>
    );
  }

  function audioIcon(type) {
    if (type === 'Pause') {
      const imageTypes = [PauseBlackIcon, PauseWhiteIcon];
      const imageClassName = ['audioPauseBlack', 'audioPauseWhite'];

      return (
        <div className={`${classes.audioArrow} ${classes[`audio${type}`]}`}>
          {imageTypes.map((imageType, index) => (
            <img
              key={imageType}
              src={imageType}
              width='16'
              height='16'
              alt='play icon'
              loading='lazy'
              className={classes[imageClassName[index]]}
            />
          ))}
        </div>
      );
    }

    const imageTypes = [PlayBlackIcon, PlayWhiteIcon];
    const imageClassName = ['audioPlayBlack', 'audioPlayWhite'];

    return (
      <div className={`${classes.audioArrow} ${classes[`audio${type}`]}`}>
        {imageTypes.map((imageType, index) => (
          <img
            key={imageType}
            src={imageType}
            width='16'
            height='16'
            alt='play icon'
            loading='lazy'
            className={classes[imageClassName[index]]}
          />
        ))}
      </div>
    );
  }

  useEffect(() => {
    if (!loading && !error && data) {
      // Process data and update the playlist
      const musicTracks = data.musics.data.map(item => {
        return {
          title: item.attributes.title,
          artist: item.attributes.artist,
          image: item.attributes.imageWebp,
          imageSmall: item.attributes.imageWebpSmall,
          audioSrc: item.attributes.song
        };
      });
      setPlaylist(musicTracks);
    }
  }, [loading, error, data]);

  useEffect(() => {
    // Update audio source when the current track changes
    if (audioRef) {
      audioRef.src = playlist[currentTrackIndex]?.audioSrc || '';

      // Set the current play time when resuming playback
      if (isPlaying && !manualPause) {
        audioRef.currentTime = currentTime;
        audioRef.play();
      }
    }
  }, [
    currentTrackIndex,
    audioRef,
    playlist,
    isPlaying,
    currentTime,
    manualPause
  ]);

  const playPauseHandler = () => {
    if (audioRef) {
      if (isPlaying) {
        // Store the current play time when pausing
        setCurrentTime(audioRef.currentTime);
        setManualPause(true);
        audioRef.pause();
      } else {
        // Resume playback from the stored play time
        setManualPause(false);
        audioRef.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const skipTrackHandler = direction => {
    if (audioRef) {
      // Increment or decrement the current track index based on the direction
      const newIndex =
        direction === 'forward'
          ? (currentTrackIndex + 1) % playlist.length
          : (currentTrackIndex - 1 + playlist.length) % playlist.length;

      // Reset currentTime if the user manually paused the current song
      const newCurrentTime = manualPause ? 0 : currentTime;

      // If the song is playing, auto-play the next song; otherwise, just go to the next song
      if (isPlaying) {
        setCurrentTime(newCurrentTime);
        setCurrentTrackIndex(newIndex);
      } else {
        setCurrentTime(newCurrentTime);
        setCurrentTrackIndex(newIndex);
        audioRef.pause(); // Pause the audio if it was playing
      }
    }
  };

  const handleEndOfTrack = () => {
    // If a song is playing and comes to the end, loop back to the beginning
    if (isPlaying) {
      setCurrentTrackIndex((currentTrackIndex + 1) % playlist.length);
    }
  };

  const handleShowMusic = () => {
    // toggle isActive state on click
    setShowMusic(current => !current);
  };

  const handleScroll = useCallback(() => {
    // Update scroll position when the page is scrolled
    setScrollPosition(window.scrollY);
  }, []);

  useEffect(() => {
    // Store the initial scroll position when the component mounts
    initialScrollRef.current = window.scrollY;

    // Add event listener for scroll events
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    // Update setShowMusic based on scroll position within a range
    const scrollDifference = Math.abs(
      scrollPosition - initialScrollRef.current
    );

    if (scrollDifference >= 20 || scrollDifference <= 20) {
      setShowMusic(false);
    }
  }, [scrollPosition]);

  return (
    <div className={classes.music}>
      <div
        className={
          showMusic
            ? [classes['audioPlayerActive'], classes['audioPlayer']].join(' ')
            : classes.audioPlayer
        }
      >
        <div className={classes.trackInfo}>
          <div className={classes.musicInfo}>
            <h3 className={classes.musicTitle}>
              {playlist[currentTrackIndex]?.title}
            </h3>
            <p className={classes.musicArtist}>
              {playlist[currentTrackIndex]?.artist}
            </p>
          </div>
          <div className={classes.musicCover}>
            <img
              src={playlist[currentTrackIndex]?.image}
              srcSet={`${playlist[currentTrackIndex]?.imageSmall} 1024w, ${playlist[currentTrackIndex]?.image} 768w`}
              sizes='(max-width: 768px) 768px, 1024px'
              alt={`album cover for ${playlist[currentTrackIndex]?.title}`}
              width='298'
              height='298'
              loading='lazy'
            />
          </div>
          <div className={classes.musicControls}>
            <div className={classes.musicEffect}>
              <span></span>
            </div>
            <button
              type='button'
              title='play the previous song'
              className={classes.prevButton}
              aria-label='Previous'
              onClick={() => skipTrackHandler('backward')}
            >
              {arrowNavigation('Left')}
            </button>
            <button
              type='button'
              title={!isPlaying ? 'play this song' : 'pause this song'}
              className={classes.playButton}
              onClick={playPauseHandler}
            >
              {isPlaying ? audioIcon('Pause') : audioIcon('Play')}
            </button>
            <button
              type='button'
              title='play the next song'
              className={classes.nextButton}
              aria-label='Next'
              onClick={() => skipTrackHandler('forward')}
            >
              {arrowNavigation('Right')}
            </button>
            <audio
              ref={ref => setAudioRef(ref)}
              onEnded={handleEndOfTrack}
              onLoadedMetadata={() => {
                // Set initial volume state if needed
              }}
            />
          </div>
        </div>
        <button className={classes.musicBtnControl} onClick={handleShowMusic}>
          <img
            className={classes.musicIcon}
            width='16'
            height='16'
            src={MusicIcon}
            alt='menu icon'
          />
        </button>
      </div>
      <MusicImage />
    </div>
  );
};

export default MusicPlayer;
