import React, { useState, Suspense } from 'react';
import { Link } from 'react-router-dom';

import { NavigationLoading } from '../Skeleton/Skeletons';
import useScrollBlock from '../../../hooks/useScrollBlock';
import Logo from '../../../assets/NockeyeLogo.svg';
import Navigation from './Navigation';
import Backdrop from './Backdrop';
import Music from '../../Music/Music';

import classes from './Header.module.css';

const Header = () => {
  const [offCanvas, setOffCanvas] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  const offCanvasHandler = event => {
    setOffCanvas(offCanvas => !offCanvas);
    offCanvas ? allowScroll() : blockScroll();
  };

  return (
    <header
      className={
        !offCanvas ? classes['offCanvasInActive'] : classes['offCanvasActive']
      }
    >
      <Link
        to={'/'}
        title='go to the homepage'
        className={classes.logoLink}
        tabIndex={0}
      >
        <img
          className={classes.logo}
          src={Logo}
          width='214'
          height='29'
          alt='nockeye logo'
        />
      </Link>
      <div className={classes.musicContent}>
        <Music />
      </div>
      <Suspense fallback={<NavigationLoading />}>
        <Navigation
          offCanvasNav={offCanvasHandler}
          tabIndex={!offCanvas ? '-1' : '0'}
        />
      </Suspense>
      <Backdrop
        offCanvasBackdrop={offCanvasHandler}
        tabIndex={!offCanvas ? '-1' : '0'}
      />
    </header>
  );
};

export default Header;
