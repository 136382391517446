import { gql } from '@apollo/client';

export const GET_NAV_SLUG = gql`
  query GetNavSlug {
    about {
      data {
        attributes {
          slug
        }
      }
    }
    service {
      data {
        attributes {
          slug
        }
      }
    }
    blog {
      data {
        attributes {
          slug
        }
      }
    }
    contact {
      data {
        attributes {
          slug
        }
      }
    }
  }
`;
